

.container {
    display: flex;
  }

  /* over right header from App.css*/
  .new-layout-header{
    position:relative !important; 
    top:0; z-index:10;
    width: 100%;
    
  }

  .new-layout-main
  {
    padding: 0px 0px 0px 0px;

    /* color: var(--goa-color-text-default);
    font-family: Acumin Pro SemiCondensed;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; */
  }

.new-layout-container {
  height: 100%;
  display: flex;
  /* min-height: 100%;
  height: 93vh; */
}

.new-layout-header {
  width: 100%;
  border-bottom: 1px solid var(--goa-color-greyscale-200);
  height: 139px;
}

.new-layout-header-section .detailsLabel {

  font-size: 14px;
  /* font-style: normal; */
  font-weight: 400;
  line-height: 20px;
  }

  .new-layout-header-section .detailsValue {

    font-size: 18px;
    /* font-style: normal; */
    font-weight: 400;
    line-height: 28px;
    }

.new-layout-header-section
{
  padding: 10px 20px 10px 20px;
}

.new-layout-header-section .heading
{
  font-size: 36px;
  /* font-style: normal; */
  font-weight: 400;
  line-height: 44px;
}

.new-layout-header-section .headingOptions
{
    display: flex;
    /* width: 262px; */
    justify-content: flex-end;
    align-items: center;
    gap: var(--goa-spacing-xl);
}

.padding-left-right10
{
  padding: 0px 10px 0px 10px;
}


.left-section .left-section-content
{
  padding: 10px 10px 10px 66px;
  overflow: auto;
}

.left-section {
  position: relative;
  min-height: 100%;
  height: calc(100vh - 22vh);
  /* border-bottom: 1px solid var(--goa-color-greyscale-200); */
  border-right: 1px solid var(--goa-color-greyscale-200);
}

  
.right-section {
  /* flex-shrink: 0; */
  width: 100%;
  /* min-height: 100%;
  height: calc(100vh - 26vh); */
}

  .right-section.right-section-content {
    padding: 10px 32px 10px 32px;
    min-height: 100%;
    height: calc(100vh - 22vh);
    overflow: auto;
  }
  
.new-layout-content {
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
  
  .sliding-section {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: width 0.5s ease-in-out;
  }
  
  .open {
    width: 33vw; /* Adjust the width as needed, up to one-third of the viewport width */
    /* height: 1000px; */
    height: 100%;
  }
  
  .closed {
    width: 66px;
  }
  
  .toggle-button {
    cursor: pointer;
    padding: 20px 20px 20px 0px;
    text-align: right;
    border-bottom: 1px solid var(--goa-color-greyscale-200);
    background: var(--goa-color-greyscale-white);
  }

  .expanded {
    width: calc(100% - 33vw); /* Adjust the width calculation */
  }
  
  .collapsed {
    width: 100%;
    overflow: hidden;
  }

  /* .header .statusHeader
  {
    padding: 0px 10px 0px 0px;
  } */

  .flex-end {
    display: flex;
    justify-content: flex-end;   
  }

  .flex-right {   
    justify-content: right;   
  }
  