:root {
  /* font-size */
  --fs-xs: 0.75rem;
  --fs-sm: 0.875rem;
  --fs-base: 1.125rem;
  /* base */
  --fs-lg: 1.25rem;
  /* h4 */
  --fs-xl: 1.5rem;
  /* h3 */
  --fs-2xl: 2.25rem;
  /* h2 */
  --fs-3xl: 3rem;
  /* h1 */

  /* font-weight */
  --fw-thin: 100;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-bold: 600;
  --fw-black: 900;

  /* colors */

  --color-gray-100: hsla(216, 10%, 99%, 1);
  --color-gray-200: hsla(216, 10%, 95%, 1);
  --color-gray-300: hsla(216, 10%, 90%, 1);
  --color-gray-400: hsla(216, 10%, 82%, 1);
  --color-gray-500: hsla(216, 10%, 72%, 1);
  --color-gray-600: hsla(216, 10%, 58%, 1);
  --color-gray-700: hsla(216, 10%, 40%, 1);
  --color-gray-800: hsla(216, 10%, 29%, 1);
  --color-gray-900: hsla(216, 10%, 19%, 1);

  --color-green: hsl(148, 100%, 26%);

  --color-primary: #0070c4;
  --color-primary-dark: #004f84;
  --color-primary-link: #0067b5;
  --color-white: #f1f1f1;
  --color-active-orange: #feba35;

  /* notices */
  --color-notice-primary: var(--color-primary);
  --color-notice-secondary: var(--color-gray-700);
  --color-notice-success: var(--color-green-700);
  --color-notice-warning: var(--color-orange);
  --color-notice-danger: var(--color-red);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  min-height: 100%;
  margin: 0;
  color: var(--color-gray-900);
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

/* .wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} */

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

hr {
  border-width: 0;
  border-top: 1px solid #ccc;
}

em {
  font-size: var(--fs-xs);
  color: var(--color-gray-600);
}

ul,
ol {
  padding-left: 0;
}

li>ul,
li>ol,
li>ul,
li>ol {
  padding-left: 0;
  margin-top: 0;
}

a {
  color: var(--color-primary-link);
  cursor: pointer;
}

a:link {
  color: var(--color-primary);
}

a:visited {
  color: var(--color-visited-link);
}

a:hover {
  color: var(--color-primary-dark);
}

a:focus {
  outline-width: thin;
  outline-style: solid;
  outline-color: var(--color-primary-dark);
  outline-offset: 0px;
}

.space-1 {
  padding: 0 0.25rem;
}

.space-2 {
  padding: 0 0.5rem;
}

.space-3 {
  padding: 0 1rem;
}

.space-4 {
  padding: 0 2rem;
}

/* == OVERRIDES */
/* TODO: move these styled into the ui-components */
a.goa-link-button,
.goa-button {
  margin-top: 0 !important;
}

.goa-header {
  padding-left: 1rem;
  padding-right: 1rem;
}

a.goa-link-button.goa--secondary,
a.goa-link-button.goa--secondary:link,
a.goa-link-button.goa--secondary:visited {
  color: var(--color-primary);
}

a.goa-link-button.goa--secondary:hover,
a.goa-link-button.goa--secondary:focus {
  color: var(--color-primary-dark);
}

button.goa-button:disabled,
button.goa-button:disabled.goa--secondary,
button.goa-button:disabled.goa--tertiary,
input[type="button"].goa-button:disabled,
input[type="button"].goa-button:disabled.goa--secondary,
input[type="button"].goa-button:disabled.goa--tertiary,
input[type="submit"].goa-button:disabled,
input[type="submit"].goa-button:disabled.goa--secondary,
input[type="submit"].goa-button:disabled.goa--tertiary {
  border: 2px solid var(--color-white) !important;
}

.goa-textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  transition: box-shadow 0.1s ease-in;
  border: 1px solid var(--color-gray-600);
  border-radius: 3px;
  background: var(--color-white);
  color: var(--color-black, #ccc);
  padding: var(--input-padding, 0.5rem);
  font-size: var(--input-font-size);
  font-family: var(--font-family);
  min-width: 100%;
  resize: none;
}

.dropdown-list {
  z-index: 999999 !important;
}

.dataTableCheckbox {
  min-height: unset !important;
  display: inline !important;
}

/* == OVERRIDES END */

.centertext {
  text-align: center !important;
}

.padding1rem {
  padding: 1rem !important;
}

.App {
  text-align: center;
  padding: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  align-items: stretch;
  height: -webkit-fill-available;
  /* padding-top: 90px; */
  /* overflow-y: auto; */
}

.status {
  background: #949494;
}

.white-color {
  color: #ffffff;
}

.white-background {
  background: #ffffff;
}

.horizontal-scrollbar
{
  /* display: inline-block !important; */
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  width: 100%;
  padding-bottom: 30px;
}

.flying-detail {
  padding-top: 10px;
}

.flying-hours {
  padding: 20px 0 20px 0;
}



.contentPadding {
  /* padding: 0px 20px 0px 20px; */
  padding: 65px 20px 0px 20px;
}

.detailPadding {
  padding-top: 65px;
}

.detail-Container {
  background: #f1f1f1;
}

.appheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  width: 1461px;
  height: 89px;
}

.logo-servicename {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 18px;

  width: 402.8px;
  height: 90px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px 24px 18px;
  gap: 10px;

  width: 172.8px;
  height: 90px;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 684px;
  height: 89px;

  /* background: #4b0707; */
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 24px 0px 0px;

  width: 488px;
  height: 89px;
}

.navigation a {
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;
  color: #333333;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  width: auto;
  height: 89px;
  text-decoration: none;
  outline-style: none;
}

.navigation a:hover {
  background-color: #f1f1f1;
}

.navigation a.active {
  display: flex;
  align-items: center;
  /* background-color: #f1f1f1; */
  border-top: 5px solid #0070c4;
  color: #000;
  outline-style: none;
}

.navaccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 196px;
  height: 89px;
}

.contentInline {
  display: inline-block;
  width: 100%;
}

.contentInline.detailHeader div{
  padding: 0px 32px 0px 0px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.text-right
{
  text-align: right;
}

.width-auto {
  width: auto;
}

.width2 {
  width: 2%;
}

.width4 {
  width: 4%;
}

.width5 {
  width: 5%;
}

.width7 {
  width: 7%;
}

.width8 {
  width: 8%;
}

.width10 {
  width: 10%;
}

.width12 {
  width: 12%;
}

.width13 {
  width: 13%;
}

.width15 {
  width: 15%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width33 {
  width: 33.33%;
}

.width35 {
  width: 35%;
}

.width12 {
  width: 12.5%;
}

.width45 {
  width: 45%;
}

.width50 {
  width: 50%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100%;
}


 /* width pixel */

 .width50px {
  min-width: 50px;
}

 .width80px {
  min-width: 80px;
}

.width100px {
  min-width: 100px;
}

.width110px {
  min-width: 110px;
}

.width120px {
  min-width: 120px;
}

.width125px {
  min-width: 125px;
}

.width130px {
  min-width: 130px;
}

.width140px {
  min-width: 140px;
}

.width145px {
  min-width: 145px;
}

.width150px {
  min-width: 150px;
}

.width160px {
  min-width: 160px;
}

.width175px {
  min-width: 175px;
}

.width180px {
  min-width: 180px;
}

.width185px {
  min-width: 185px;
}

.width190px {
  min-width: 190px;
}

.width200px {
  min-width: 200px;
}

.width210px {
  min-width: 210px;
}

.width215px {
  min-width: 215px;
}

.width220px {
  min-width: 220px;
}

.width225px {
  min-width: 225px;
}

.width230px {
  min-width: 230px;
}

.width240px {
  min-width: 240px;
}

.width250px {
  min-width: 250px;
}

.width280px {
  min-width: 280px;
}

.width300px {
  min-width: 300px;
}



.paddingTop20px {
  padding-top: 20px;
}

.paddingBottom20px {
  padding-bottom: 20px;
}

.paddingRight10px {
  padding-right: 10px;
}

.paddingLeft10px {
  padding-left: 10px;
}

.paddingLeft0px {
  padding-left: 0px !important;
}

.paddingTopRightLeftBottom20px {
  padding: 0px 20px 20px 20px;
}

.paddingLeft20px {
  padding-left: 20px;
}

.paddingRight20px {
  padding-right: 20px;
}

.paddingRight30px {
  padding-right: 30px;
}

.fontBold
{
  font-weight: bold;
}

/* --------------------------------------------------------- */
/* Dashboard */
.dashboard {
  /* background: #0081A2; */
  height: 150px;
  border-bottom: 8px solid #c8eefa;
  margin-bottom: 30px;
}

.sidebar {
  flex: 0 0 200px;
  width: 200px;
  background-color: #ffffff;
  padding: 10px;
  position: fixed;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: #000;
  padding: 10px;
  text-decoration: none;
}

.activeLink {
  background-color: #f1f1f1;
  color: #000;
  display: block;
}

.normalLink {
  background-color: #ffffff;
  color: #000;
  display: block;
}

.content {
  margin: 0px;
  /* margin-left: 200px; */
  width: 100%;
  flex-grow: 1;
}

.content-Container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.login-form {
  padding-top: 50px;
}

.test-form {
  padding-top: 80px;
}

/* --------------------------------------------------------- */

.pageHeading {
  padding: 10px 0px 10px 0px;
  font-weight: 400;
  font-size: 26px;
  /* line-height: 30px; */
}

.header-button {
  padding: 10px 0px 0px 0px;
}

.statusHeader {
  padding: 0px 10px 0px 0px;
}

.page-header {
  margin-top: 10px;
}

.section-margin-top-20{
  margin-top: 20px;
}

.header-content {
  /* overflow: auto; */
  /* padding-bottom: 10px; */
  padding: 0;
}

.header-content .pageTitle {
  float: left;
}

.header-content .pageButtons {
  float: left;
}

.header-content .pageSearch {
  float: right;
}

.filter-content {
  /* overflow: auto; */
  /* padding-bottom: 10px; */
  padding: 0;
}

/* Accordian --------------------------------------------------------- */
.accordion {
  padding: 0px;
  width: 100%;
}

.insideHeader {
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 5px;
}

.accordion .firstSection {
  width: 42%;
  border-right: 1px solid #DCDCDC;
  padding-right: 20px;

}

.accordion .secondSection {
  width: 43%;
  padding: 0px 20px 0px 20px;
}

.accordion .thirdSection {
  width: 15%;
  border-left: 1px solid #DCDCDC;
  padding: 0px 20px 0px 20px;
}

.goa-radio-label {
  margin-right: 5rem !important;
}

summary .title {
  align-items: unset !important;
}


.callout {
  position: relative;

}

/* --------------------------------------------------------- */
.gridTable {
  width: 100%;
}

.gridTable tr {
  line-height: 4px !important;
}

/* ------------------------------------------------------------------ */
.visible {
  display: block;
}

.not-visible {
  display: none;
}

.header {
  position: fixed !important;
  top: 0;
  z-index: 10;
  width: 100%;
}

.footer {
  /* position: absolute;
  bottom: 0;
  /* bottom: 0;
  align-self: stretch;
  position: relative;
  clear: both; */
  bottom: 0;
  z-index: 99999;
  margin-top: auto;
  position: static;
}


.footer-absolute {
  bottom: 0;
  z-index: 9;
  margin-top: auto;
  position: absolute !important;
}


/* Screen ------------------------------------------------------------------ */

* {
  box-sizing: border-box;
}

.column {
  float: left;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 0px;
}

.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

/* Table ------------------------------------------------------------------ */

.divTable {
  width: 100%;
  max-height: calc(100vh - 450px);
  min-height: calc(100vh - 450px);
  overflow-y: auto;
}

.divTable thead tr {
  position: sticky !important;
  top: 0 !important;
  /* Displayed on top of other rows when scrolling */
  z-index: 99999 !important;
}

.table-body tr td {
  padding: 5px;
}

/* Pagination ------------------------------------------------------------------ */
.pagination {
  padding: 20px 0px 0px 0px;
}

.pagination goa-dropdown {
  --goa-space-xs: 3px;
}

.pagination goa-pagination {
  --goa-space-xs: 3px;
}

.pagination goa-pagination {
  --goa-font-size-4: 1.125rem;
}

/* End Pagination ------------------------------------------------------------------ */

/* Section ------------------------------------------------------------------ */
.detail-section {
  padding: 20px 0px 0px 0px;
}

.sub-section {
  padding: 0px 20px 0px 20px;
}

.sub-section goa-button {
  height: none !important;
  z-index: 99999 !important;
}

.detail-section details {
  max-width: unset !important;
}


#goa-app-header .app-header {
  padding: 0 10px 0 10px !important;
}




/* Multi-select dropdown ------------------------------------------------------------------ */
.rmsc .dropdown-container {
  border: 1px solid var(--goa-color-greyscale-700) !important;
  border-radius: var(--goa-border-radius-m) !important;
}

.rmsc .dropdown-content {
  z-index: 99999999 !important;
}

.rmsc .gray {
  color: var(--goa-color-text-default) !important;
}

.select-dropdown {
  width: 90%;
}

.css-13cymwt-control {
  border: 1px solid var(--goa-color-greyscale-700) !important;
  border-radius: var(--goa-border-radius-m) !important;
}

.css-b62m3t-container .css-1nmdiq5-menu {
  z-index: 99999999999 !important;
}

/* react-select (Edit select) ------------------------------------------------------------------ */

.css-1nmdiq5-menu {
  z-index: 99999999 !important;
}

.css-1jqq78o-placeholder {
  color: var(--goa-color-text-default) !important;
}

.css-1xc3v61-indicatorContainer {
  color: var(--goa-color-text-default) !important;
}


.css-13cymwt-control {
  min-height: 42px !important;
}

.css-13cymwt-control:hover {
  box-shadow: 0 0 0 3px var(--goa-color-interactive-hover) !important;
  /* border-color: var(--goa-color-interactive-focus) !important; */
}

.css-13cymwt-control:active {
  box-shadow: 0 0 0 3px var(--goa-color-interactive-focus) !important;
  border-color: var(--goa-color-interactive-focus) !important;
}

.css-t3ipsp-control {
  min-height: 42px !important;
  box-shadow: 0 0 0 3px var(--goa-color-interactive-focus) !important;
  border-color: var(--goa-color-interactive-focus) !important;
}

.css-t3ipsp-control:hover {
  border-color: var(--goa-color-interactive-hover) !important;
}

/* label ------------------------------------------------------------------ */
.label {
  display: block;
  font-weight: var(--goa-font-weight-bold);
  color: var(--goa-color-text-default);
  font-size: var(--goa-font-size-4);
  /* padding: 0.5rem 0; */
  padding-bottom: 0.5rem;
}

.label .mandatory {
  color: #EC040B !important;
}

.label em {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #EC040B !important;
  margin-left: 5px
}

.divUlLi ul.b {
  list-style-type: square;
  padding-left: 35px;
}

/* Responsive form ------------------------------------------------------------------ */

.form-row {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap;
  /* IE10 */
  flex-wrap: wrap;
  margin: 0 -16px;
}

.col-15 {
  -ms-flex: 15%;
  /* IE10 */
  flex: 15%;
}

.col-25 {
  -ms-flex: 25%;
  /* IE10 */
  flex: 25%;
}

.col-50 {
  -ms-flex: 50%;
  /* IE10 */
  flex: 50%;
}

.col-75 {
  -ms-flex: 75%;
  /* IE10 */
  flex: 75%;
}

.col-100 {
  -ms-flex: 100%;
  /* IE10 */
  flex: 100%;
}

.col-25,
.col-50,
.col-75 {
  padding: 0 16px;
}

/* //flight report status */

.status-inProgress {
  background: #949494;
}

.status-signedoff {
  background: #949494;
}

.status-returned {
  background: #949494;
}

/* start expense section */

.expense-section-header {
  padding-left: 20px; 
  padding-bottom: 20px;
}

.expense-section {
  padding: 12px 16px 12px 32px;
}

.notes-section {
  display: flex;
  padding: 70px 39px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.note-container {
  display: flex;
  padding: --goa-spacing-l;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
  width: 849px !important;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.edit-note {
  width: 100%;
}

.note-title {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.note-title-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.note-title-date {
  color: Interactive/Visited;
  text-align: center;

  /* Large screen/Body/Medium */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
}


.fuel,
.flight-crew {
  height: 165px;
}

.fuel-edit {
  height: 248px;
}

.flight-crew-edit {
  height: 248px;
}

.expense-section .fuel,
.fuel-edit,
.flight-crew,
.flight-crew-edit,
.flight-crew-eligibility {
  /* width: 734px;
height: 178px; */
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #DCDCDC;
}

/* .fuel .flight-crew .content{
  padding: 0px 20px 0px 20px;
  border-radius: 0px, 0px, 4px, 4px;
  border: 1px;
  gap: 16px;
} */


/* Start UnserviceableReport-section */
.unserviceableReport-section .unserviceableReport {
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #DCDCDC;
  padding-bottom: 20px;
}

.unserviceableReport .content {
  border-radius: 0px, 0px, 4px, 4px;
  border: 1px;
  gap: 16px;
}

.unserviceableReport .content.standToStandDown div{
  padding-left: 20px;
  padding-right: 20px;
}

.unserviceableReport .content .unserviceable{
  padding-left: 20px;
  padding-right: 20px;
}

.unserviceableLog {
  padding: 20px 20px 10px 0px;
}

.unserviceableReport-show
{
  display: block;
}

.unserviceableReport-hide
{
  display: none;
}

/* End UnserviceableReport-section */


.fuel .content,
.fuel-edit .content,
.flight-crew .content,
.flight-crew-edit .content {
  padding: 0px 20px 0px 20px;
  border-radius: 0px, 0px, 4px, 4px;
  border: 1px;
  gap: 16px;
}

.expense-section .crew {
  padding-left: 20px 0px 20px;
  overflow: hidden;
}


.error-border {
  border: 1px solid #F04247;
  /* Set the border color to red on validation error */
}

/* end expense section */

.sort-note-toggle {
  display: flex;
  width: 849px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
}

.note-content {
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: pre-wrap;  
  max-width: 100%; 
}

.flyingHoursDelete
{
  width: 100%;
  padding-top: 50px;
}

.flyingHoursDelete .setBackground
{
  background-color: #003B70;
  padding: 16px 24px 16px 24px;
}

/* goa-checkbox */

.goa-checkbox-table-td {
padding: 20px 0px 0px 20px;
text-align: center;
vertical-align: middle;
}

/* GOA TAB */
.sc-bgqQcB
{
  width: 100%;
}

/* goa-table */
/* goa-table thead th {
  padding: 0.3rem !important;
}
goa-table td {
  padding: 0.3rem 0.3rem !important;
} */